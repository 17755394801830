import React, { useState, useEffect, useRef, Fragment } from "react";
import { Modal, Spin, Space } from "antd";
import { Combobox, Transition } from "@headlessui/react";
import { FiChevronDown } from "react-icons/fi";
import { LoadingOutlined } from "@ant-design/icons";
import { Toaster, toast } from "sonner";
import axios from "axios";
import Maploading from "../../loading/Maploading";
import SmallSpinner from "../../loading/SmallSpinner";
import { IoClose } from "react-icons/io5";

const StatesModal = ({ closeModal, fetchUserData }) => {
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const comboboxRef = useRef(null);

  useEffect(() => {
    const fetchStates = async () => {
      const countryId = localStorage.getItem("countryId");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/states/${countryId}`
        );
        setStates(response.data.content);
        setSelectedState(response.data.content[0]);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to fetch states!");
        setLoading(false);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (comboboxRef.current) {
      comboboxRef.current.focus();
    }
  }, [loading]);

  const filteredStates =
    query === ""
      ? states
      : states.filter((state) =>
          state.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const handleSave = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    setSaving(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/user/update/${userId}`,
        { state_id: selectedState.id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      localStorage.setItem("stateId", selectedState.id);
      toast.success(`State ${response.data.message}`);
      fetchUserData();
      setSaving(false);
      closeModal();
    } catch (error) {
      toast.error("There was an error updating the state!");
      setSaving(false);
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={closeModal}
      footer={null}
      closeIcon={<IoClose className="text-gray-50" />}
    >
      <Toaster />
      {loading ? (
        <SmallSpinner />
      ) : (
        <div className="w-full h-auto py-5 px-10 flex flex-col space-y-4">
          <h1 className="text-lg text-gray-700 font-bold">State</h1>
          <div className="w-full">
            <Combobox value={selectedState} onChange={setSelectedState}>
              <div className="relative mt-1">
                <div className="relative w-full cursor-default p-2 border-2 bg-white text-left rounded-full sm:text-sm">
                  <Combobox.Input
                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 outline-none"
                    displayValue={(state) => state?.name || ""}
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder="Type to search..."
                    ref={comboboxRef}
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <FiChevronDown
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setQuery("")}
                >
                  <Combobox.Options className="border-gray-300 border-2 rounded-2xl overflow-y-auto mt-5 max-h-60 scrollbar-hide">
                    {filteredStates.length === 0 && query !== "" ? (
                      <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                        Nothing found.
                      </div>
                    ) : (
                      filteredStates.map((state) => (
                        <Combobox.Option
                          key={state.id}
                          className={({ active }) =>
                            `text-gray-900 px-4 py-2 cursor-pointer hover:bg-gray-200 ${
                              active
                                ? "bg-gray-300 text-black"
                                : "text-gray-900"
                            }`
                          }
                          value={state}
                        >
                          {({ selected, active }) => (
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {state.name}
                            </span>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </Transition>
              </div>
            </Combobox>
          </div>
          <button
            className="w-full p-3 text-white font-bold bg-[#404041] hover:scale-95 rounded-full"
            onClick={handleSave}
          >
            {saving && (
              <Space className="mr-3">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: "#fff" }}
                      spin
                    />
                  }
                  size="medium"
                />
              </Space>
            )}
            {saving ? "Saving..." : "Save"}
          </button>
        </div>
      )}
    </Modal>
  );
};

export default StatesModal;
