import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import OtpScreen from "./pages/OtpScreen";
import ForgetPassword from "./pages/ForgetPassword";
import NewPassword from "./pages/NewPassword";
import { Toaster, toast } from "sonner";
import Footer from "./components/Footer";
import MainLogoLoader from "./components/loading/MainLogoLoader";
import TermAndCondition from "./pages/TermAndCondition";
import OtpScreenNew from "./pages/OtpScreenNew";
import Hello from "./pages/Hello";
import EmailEnterForgetPassword from "./pages/EmailEnterForgetPassword";
import HomeExperimental from "./pages/HomeExperimental";

function App() {
  return (
    <Router>
      <div className="App  ">
        <Toaster richColors />
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/ex" element={<HomeExperimental />} />
          <Route path="/login" element={<Login />} />
          <Route path="/9974590" element={<MainLogoLoader />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/otp" element={<OtpScreen />} />
          <Route path="/verify-otp" element={<OtpScreenNew />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/email-fp" element={<EmailEnterForgetPassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/policy/:name" element={<TermAndCondition />} />
          <Route path="/hello" element={<Hello />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
