import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import searchicon from "../assets/radix-icons_magnifying-glass@2x.svg";
import axios from "axios";
import { List } from "antd";
import {
  setSearchText,
  clearSearchText,
} from "../redux-store/postSearch/searchSlice";
import {
  setUserSearchText, // Import setUserSearchText action
  clearUserSearchText, // Import clearUserSearchText action
} from "../redux-store/userSearch/userSearchSlice";
import { toggleActiveTag } from "../redux-store/tagFilter/tagSlice";
import Tags from "./mini-components/Tags";
import translations from "../languages";
import { IoClose } from "react-icons/io5";
import { Popover } from "@headlessui/react";

const Explore = ({ activeComponent }) => {
  const location = useLocation();
  const isBookmarksRoute = location.pathname.includes("/explore");
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.search.searchText);
  const activeTags = useSelector((state) => state.tag.activeTags);
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("");
  const userSearchText = useSelector(
    (state) => state.userSearch.userSearchText
  );
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].headings;
  const tagNames = translations[selectedLanguage].tags;

  const options = {
    emp_post: tagNames.emp_post,
    job_seeker_post: tagNames.job_seeker_post,
    emp_profile: tagNames.emp_profile,
    job_seeker_profile: tagNames.job_seeker_profile,
  };

  useEffect(() => {
    if (query) {
      const fetchSuggestions = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/tags?string=${query}`
          );
          setSuggestions(response.data.content.map((item) => item.tag));
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      };

      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [query]);

  useEffect(() => {
    if (activeComponent !== texts.home && activeComponent !== texts.explore) {
      handleClearSearch();
    }
  }, [activeComponent, texts.home, texts.explore]);

  const handleTagClick = (tag) => {
    dispatch(toggleActiveTag(tag));
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    dispatch(setUserSearchText(e.target.value));
    setQuery(e.target.value);

    if (value === "") {
      handleClearSearch();
    }
  };

  const handleClearSearch = () => {
    dispatch(clearSearchText());
    dispatch(clearUserSearchText());
    setQuery("");
  };

  const handleSuggestionClick = (suggestion) => {
    dispatch(setSearchText(suggestion));
    setQuery("");
    dispatch(clearUserSearchText());
    setSuggestions([]);
  };

  const highlightMatch = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="font-bold text-orange-400">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div className="p-2 flex flex-col justify-start items-start bg-white mt-2 ml-2 relative z-50 suggestion-layer">
      <span className="text-lg font-bold text-black flex-1">
        {activeComponent === texts.home ? texts.explore : activeComponent}
      </span>
      <div className="flex flex-col w-full mt-3 relative">
        <div className="flex space-x-2 h-12 rounded-full border-2 border-gray-400 outline-none px-5 items-center">
          <img
            src={searchicon}
            alt=""
            className="h-6 w-6 shrink-0 group-hover:hidden"
          />
          <input
            type="text"
            className="outline-none flex-grow"
            placeholder="search"
            value={query || searchText}
            onChange={handleInputChange}
          />
          {(query || searchText) && (
            <IoClose
              className="text-2xl text-gray-700 font-bold cursor-pointer"
              onClick={handleClearSearch}
            />
          )}
        </div>
        {suggestions.length > 0 && (
          <div
            className="bg-white   rounded-xl  mt-2 mb-2 scrollbar-hide  w-full top-12 shadow-sm     right-1  z-50  absolute "
            style={{ maxHeight: "250px", overflowY: "auto" }}
          >
            <List
              bordered
              dataSource={suggestions}
              className="bg-slate-50 w-full z-50 mr-5 border-none"
              renderItem={(suggestion) => (
                <List.Item
                  className="cursor-pointer"
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {highlightMatch(suggestion, query)}
                </List.Item>
              )}
            />
          </div>
        )}
      </div>
      {isBookmarksRoute && (
        <Tags
          options={options}
          activeTags={activeTags}
          onTagClick={handleTagClick}
        />
      )}
    </div>
  );
};

export default Explore;
