import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  postDetails: {
    description: "",
    photos: [],
    videos: [],
  },
};

const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setPostDetails: (state, action) => {
      state.postDetails = action.payload;
    },
    clearPostDetails: (state) => {
      state.postDetails = initialState.postDetails;
    },
  },
});

export const { setPostDetails, clearPostDetails } = postSlice.actions;

export default postSlice.reducer;
