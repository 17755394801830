const translations = {
  English: {
    sideBar: {
      Home: "Home",
      Explore: "Explore",
      Messages: "Messages",
      Bookmarks: "Bookmarks",
      Notifications: "Notifications",
      Subscription: "Subscription",
      Profile: "My Profile",
      Settings: "Settings",
      LogOut: "Log Out",
      LogIn: "Log In",
    },
    settingsOne: {
      aboutMe: "About Me",
      username: "Username",
      email: "Email",
      phone: "Phone",
      myPassword: "My Password",
      role: "Role",
      readMore: "...Read more",
    },
    settingsTwo: {
      country: "Country",
      state: "State",
      city: "City",
      displayLanguage: "Display Language",
      readMore: "...Read more",
    },
    settingsThree: {
      termsAndConditions: "Terms & Conditions",
      refundPolicy: "Refund Policy",
      privacyPolicy: "Privacy Policy",
      cancellationPolicy: "Cancellation Policy",
      readMore: "...Read more",
    },

    headings: {
      settings: "Settings",
      notifications: "Notifications",
      myProfile: "My Profile",
      profile: "Profile",
      homepage: "Homepage",
      latestPosts: "Latest Posts",
      explore: "Explore",
      home: "Home",
      messages: "Messages",
      bookmarks: "Bookmarks",
      subscription: "Subscription",
      users: "Users",
    },
    buttons: {
      publish: "Publish",
      subscribeNow: "Subscribe Now",
      showMore: "Show More",
    },
    tags: {
      job_seeker_post: "Job Seeker Posts",
      job_seeker_profile: "Job Seeker Profiles",
      emp_profile: "Employer Profiles",
      emp_post: "Job Posts",
    },
    types: {
      jobseeker: "Job Seeker",
      employer: "Employer",
    },

    actions: {
      reportUser: "Report User",
      reportPost: "Report Post",
      bookmarkUser: "Bookmark User",
      editPost: "Edit Post",
      deletePost: "Delete Post",
      pinToTop: "Pin to the Top",
      blockUser: "Block User",
      deleteChat: "Delete Chat",
      removeBookmark: "Remove Bookmark",
    },
    bookmarkOptions: {
      chats: "Chats",
      posts: "Posts",
      profiles: "Profiles",
    },
    subscriptionTexts: {
      annual: "Annual Subscription",
      annualTexts:
        "With a free account, you'll encounter brief ads to assist Boca En Boca in connecting Employers with Job Seekers. Upgrade to our Annual Subscription plan to enjoy an ad-free experience throughout the app.",
      annualPrice: "$9.99/Year",
      subscribeAccess: "Subscribe Access",
      paywithcard: "Pay with card",
      thankYouTexts:
        "Thank you for your support in helping us to connect Employers with Job Seekers.",
      continue: "Continue",
    },
    profileExtras: {
      posts: "Posts",
      bookmarks: "Bookmarks",
      responseTime: "Response Time",
      about: "About Me",
    },
    logoutmodal: {
      text: "Are you sure, You want to logout?",
      cancel: "Cancel",
      confirm: "  Confirm",
    },
    loginmodal: {
      text: " Please log in or create a free account to continue.",
      button: "  Take me to Login/Signup",
    },
  },
  Spanish: {
    sideBar: {
      Home: "Hogar",
      Explore: "Explorar",
      Messages: "Mensajes",
      Bookmarks: "Marcadores",
      Notifications: "Notificaciones",
      Subscription: "Suscripción",
      Profile: "Mi perfil",
      Settings: "Configuración",
      LogOut: "Cerrar sesión",
      LogIn: "Iniciar sesión",
    },
    settingsOne: {
      aboutMe: "Acerca de mí",
      username: "Nombre de usuario",
      email: "Correo electrónico",
      phone: "Teléfono",
      myPassword: "Mi contraseña",
      role: "Rol",
      readMore: "...Leer más",
    },
    settingsTwo: {
      country: "País",
      state: "Estado",
      city: "Ciudad",
      displayLanguage: "Idioma de visualización",
      readMore: "...Leer más",
    },
    settingsThree: {
      termsAndConditions: "Términos y condiciones",
      refundPolicy: "Política de reembolso",
      privacyPolicy: "Política de privacidad",
      cancellationPolicy: "Política de cancelación",
      readMore: "...Leer más",
    },
    headings: {
      settings: "Configuracións",
      notifications: "Notificaciones",
      myProfile: "Mi perfil",
      profile: "Perfil",
      homepage: "Página principal",
      latestPosts: "últimas publicaciones",
      explore: "Explorar",
      home: "Hogar",
      messages: "Mensajes",
      bookmarks: "Marcadores",
      subscription: "Suscripción",
      users: "Usuarios",
    },
    buttons: {
      publish: "Publicar",
      subscribeNow: "Suscríbete ahora",
      showMore: "Mostrar más",
    },
    tags: {
      job_seeker_post: "Publicaciones de Buscadores de Empleo",
      job_seeker_profile: "Perfiles de Buscadores de Empleo",
      emp_profile: "Perfiles de Empleadores",
      emp_post: "Ofertas de Trabajo",
    },
    types: {
      jobseeker: "Buscador de empleo",
      employer: "Empleador",
    },
    actions: {
      reportUser: "Reportar usuario",
      reportPost: "Reportar publicación",
      bookmarkUser: "Marcar usuario",
      editPost: "Editar publicación",
      deletePost: "Eliminar publicación",
      pinToTop: "Fijar en la parte superior",
      blockUser: "Bloquear usuario",
      deleteChat: "Eliminar chat",
      removeBookmark: "Quitar marcador",
    },

    bookmarkOptions: {
      chats: "Chats",
      posts: "Publicaciones",
      profiles: "Perfiles",
    },
    subscriptionTexts: {
      annual: "Suscripción Anual",
      annualTexts:
        "Con una cuenta gratuita, encontrará anuncios breves para ayudar a Boca En Boca a conectar a los empleadores con los solicitantes de empleo. Actualice a nuestro plan de suscripción anual para disfrutar de una experiencia sin publicidad en toda la aplicación",
      annualPrice: "$9,99/año",
      subscribeAccess: "Acceso de suscripción",
      paywithcard: "Pagar con tarjeta",
      thankYouTexts:
        "Gracias por su apoyo para ayudarnos a conectar a los empleadores con los solicitantes de empleo",
      continue: "Continuar",
    },
    profileExtras: {
      posts: "Publicaciones",
      bookmarks: "Marcadores",
      responseTime: "Tiempo de respuesta",
      about: "Acerca de mí",
    },
    logoutmodal: {
      text: "Estás seguro, quieres cerrar sesión?",
      cancel: "Cancelar",
      confirm: "Confirmar",
    },
    loginmodal: {
      text: "Inicie sesión o cree una cuenta gratuita para continuar.",
      button: "Llévame a Iniciar sesión/Registrarse",
    },
  },
};

export default translations;
