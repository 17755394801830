import React from "react";
import SettingsListOne from "./SettingsListOne";
import SettingsListTwo from "./SettingsListTwo";
import SettingsListThree from "./SettingsListThree";
import SocialsTooltip from "./SocialsTooltip";
import { useSelector } from "react-redux";
import translations from "../../languages";
const SettingsMain = () => {
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;
  return (
    <div className="scrollbar-hide w-auto flex flex-col py-10">
      <h1 className="text-lg font-bold mb-2 px-8">{texts.settings}</h1>
      <hr />
      <SettingsListOne />
      <div className="w-full h-2 bg-gray-100"></div>
      <SettingsListTwo />
      <div className="w-full h-2 bg-gray-100"></div>
      <SettingsListThree />
      <div className="w-full h-2 bg-gray-100"></div>
      {/* <SocialsTooltip /> */}
      <p className=" flex text-center items-center justify-center mt-4 text-md text-gray-600">
        <a href="https://www.bocaenboca.com" target="_blank">
          {" "}
          BocaEnBoca.com
        </a>
      </p>
    </div>
  );
};

export default SettingsMain;
