import React from "react";
import TabsNavigation from "./TabsNavigation";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
const BookmarkMain = () => {
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;
  return (
    <div className="scrollbar-hide w-auto py-10">
      <h1 className="text-lg font-bold mb-2 px-8 ">{texts.bookmarks}</h1>
      <hr />
      <TabsNavigation />
    </div>
  );
};

export default BookmarkMain;
