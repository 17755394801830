import React from "react";

const HomeExperimental = () => {
  return (
    <div className="w-full h-auto flex  items-center justify-center gap-2">
      <div className="w-72 bg-gray-600  rounded-md h-screen"></div>
      <div className="w-[50rem] bg-gray-600  rounded-md h-screen"></div>
      <div className="w-72 bg-gray-600  rounded-md h-screen"></div>
    </div>
  );
};

export default HomeExperimental;
