import React, { useEffect, useRef } from "react";
import ReceiveingMessage from "./chatbox/ReceiveingMessage";
import SendingMessage from "./chatbox/SendingMessage";

const SingleChat = ({ conversationData }) => {
  const storedUserId = localStorage.getItem("userId");
  // const endOfMessagesRef = useRef(null);

  // Check if conversationData exists and is an array before mapping
  if (!conversationData || !Array.isArray(conversationData)) {
    return (
      <div className="h-[27rem] flex justify-center items-center text-orange-300">
        No messages yet
      </div>
    );
  }

  // useEffect that scrolls to the bottom only when the user sends a message
  // useEffect(() => {
  //   const handleScroll = () => {
  //     // You can add conditions to determine when to scroll
  //     // For example, only scroll when a new message is from the current user
  //     if (conversationData.length > 0) {
  //       const lastMessage = conversationData[conversationData.length - 1];
  //       if (lastMessage.user_id === storedUserId) {
  //         endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  //       }
  //     }
  //   };

  //   handleScroll();
  // }, [conversationData, storedUserId]);

  return (
    <div className="h-[90vh] overflow-y-auto scrollbar-hide">
      <div className="w-72 py-1 bg-[#F2F2F2] rounded-full mx-auto text-center text-sm text-[#9B9B9B]">
        Monday, April 1 At 1:32 PM
      </div>
      {conversationData.map((chat, index) => {
        if (chat.user_id === storedUserId) {
          return <SendingMessage key={index} chat={chat} />;
        } else {
          return <ReceiveingMessage key={index} chat={chat} />;
        }
      })}
      {/* <div ref={endOfMessagesRef} /> */}
    </div>
  );
};

export default SingleChat;
