import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";

import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoShareOutline } from "react-icons/io5";
import { MdOutlineModeComment } from "react-icons/md";
import { CiBookmark } from "react-icons/ci";
import { BsThreeDots } from "react-icons/bs";
import Loading from "./Loading";

const PostShimmer = () => {
  return (
    <div>
      <div className="py-3 px-10 bg-white rounded-xl mb-3 w-full scrollbar-hide z-999 ">
        <div className="flex">
          <div
            className={`w-12 h-12 rounded-full p-0 object-cover text-orange-500 bg-gray-300 animate-pulse`}
            alt="User Avatar"
          />
          <div className="flex flex-col ml-2">
            <span className="text-lg font-bold bg-gray-300 w-32 h-3 animate-pulse rounded-full mt-3 "></span>
            <span
              className={`text-sm flex items-center w-20 h-2 rounded-full bg-gray-300 animate-pulse mt-1`}
            ></span>
          </div>
          <div className="flex justify-end text-xs flex-col ml-auto relative">
            <Menu>
              <MenuButton>
                {/* <BsThreeDots className="text-3xl ml-auto cursor-pointer" /> */}
              </MenuButton>
              <Transition
                enter="transition ease-out duration-75"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              ></Transition>
            </Menu>
          </div>
        </div>
        <div className="flex flex-col w-full gap-3 mt-5">
          <p className="h-3 w-full bg-gray-300 rounded-full animate-pulse"></p>
          <p className="h-3 w-11/12 bg-gray-300 rounded-full animate-pulse"></p>
          <p className="h-3 w-7/12 bg-gray-300 rounded-full animate-pulse"></p>
          <p className="h-3 w-9/12 bg-gray-300 rounded-full animate-pulse"></p>
          <p className="h-3 w-5/12 bg-gray-300 rounded-full animate-pulse"></p>
        </div>
        <div className="flex mt-5  ">
          <div className="h-96 mr-2 rounded-md object-cover w-5/12 bg-gray-300 animate-pulse" />
          <div className="h-96 mr-2 rounded-md object-cover w-5/12 bg-gray-300 animate-pulse" />
          <div className="h-96 mr-2 rounded-md object-cover w-5/12 bg-gray-300 animate-pulse" />
        </div>

        <div className="flex px-3 w-full mt-10 mb-5"></div>
        <hr />
      </div>
    </div>
  );
};

export default PostShimmer;
