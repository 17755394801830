import React from "react";
import AllNotifications from "./AllNotifications";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
const SideBarNotification = () => {
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].headings;
  return (
    <div className="scrollbar-hide w-auto py-10">
      <h1 className="text-lg font-bold mb-2 px-8 "> {texts.notifications}</h1>
      <hr />
      <AllNotifications />
    </div>
  );
};

export default SideBarNotification;
