import React, { useState, useEffect } from "react";
import { BsThreeDots } from "react-icons/bs";
import ProfileBanner from "./ProfileBanner";
import Posts from "../components/homepage/Posts";
import postdata from "./postData";
import { fetchUserDetails } from "../apis";
import MainLogoLoader from "../components/loading/MainLogoLoader";
import axios from "axios"; // Import Axios
import ProfileShimmer from "../components/loading/ProfileShimmer";
import { useDispatch, useSelector } from "react-redux";
import translations from "../languages";

const ProfileMain = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [postList, setPostList] = useState([]);
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].headings;
  const fetchUserData = async () => {
    try {
      const userDetailsData = await fetchUserDetails();
      setUserDetails(userDetailsData.content);
      setUserId(userDetailsData.content.id);
      fetchData();
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setLoading(false);
    }
  };
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const iduser = localStorage.getItem("userId");
      const postListResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/post/list?limit=100&offset=1&user_id=${iduser}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPostList(postListResponse.data.content.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user details and post list:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const updateProfileImage = async (profileImage) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("profile_image", profileImage);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/update/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        fetchUserData();
      }
    } catch (error) {
      console.error("Error updating profile image:", error);
    }
  };

  const updateBannerImage = async (bannerImage) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("banner_image", bannerImage);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/update/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // If the update is successful, fetch user data again to display the latest details
      if (response.status === 200) {
        fetchUserData();
      }
    } catch (error) {
      console.error("Error updating banner image:", error);
    }
  };

  return (
    <div className="scrollbar-hide w-full py-10">
      <div className="flex ">
        <h1 className="text-lg font-bold mb-2 px-8">{texts.myProfile}</h1>
        <BsThreeDots className="ml-auto text-3xl mr-2" />
      </div>

      <hr />
      {loading ? (
        <ProfileShimmer />
      ) : (
        <ProfileBanner
          userDetails={userDetails}
          updateProfileImage={updateProfileImage}
          updateBannerImage={updateBannerImage}
        />
      )}
      <hr />

      <Posts postdatas={postList} />
    </div>
  );
};

export default ProfileMain;
